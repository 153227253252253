<template>
  <div class="overflow-y-auto">
    <NotificationItem
      class="mb-3"
      v-for="(item, i) in list"
      :key="i"
      :messageRecord="item"
      v-scroll="onScroll"
    />
  </div>
</template>

<script lang='js'>
import NotificationItem from './components/NotificationItem.vue';
import { getSettingArr } from "@/utils/helper";
import  api  from "@/api"
export default {
  name: 'Notifications',
  components: { NotificationItem },
  props: {},
  data: function() {
    return {
        list: [],
      pageNumber: 0,
      pageSize: 20,
      totalPage: false,
    };
  },
  methods:{
    async getSysNews(){
      this.pageNumber++;
      let params={
        pageSize: this.pageSize,
        pageNumber: this.pageNumber,
        owner:this.$store.state.did,
        pushType:1,
        pushTypes:getSettingArr()
      };
      let res = await api.home.sysMessage(params)
      this.list = res.data.list
    },
    onScroll(e) {
      const {
        scrollTop,
        clientHeight,
        scrollHeight,
      } = e.target.scrollingElement;
      if (
          scrollTop + clientHeight === scrollHeight &&
          this.totalPage > this.pageNumber
      ) {
        this.getSysNews();
      }
    },
  },
  mounted(){
    this.getSysNews()
  }
};

</script>

<style lang='scss' scoped></style>
